
.parent {
display: flex;
flex-direction: column;
height: 15mm;
width: 25mm;
}

.main2 {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  border-top: 1px solid #000;
}
.img3 {
  width: 20px;
}

.small {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  font-size: 15px;
}

.head2 {
  display: flex;
  align-items: center;
  justify-content: center;
}


  
.qr {
  flex-grow: 1;
}

.logo2 {
  display: flex;
  justify-content: center;
  border-right:1px solid #aaa;
  margin-right:5px;
}

body {
  font-family: Verdana;
}


.sample .img2 {
  width: 10px;
}
