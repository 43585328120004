
.parent {
  display: flex;
  flex-direction: column;
  height: 50mm;
  max-height: 200px;
  width: 50mm;
  max-width: 200px;
  }
  
  .main {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    border: 2px solid #000;
    margin: 5px;
    margin-bottom: 20px;
  }
  .img2 {
    width: 50px;
  }

  .small {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    font-size: 15px;
  }

  .code {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    font-size: 50px;
    font-weight: bold;
  }

  .head {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 10px 10px 0;
    height: 25px;
    margin-top: 5px;
  }

  
    
  .qr {
    flex-grow: 1;
  }

  .logo {
    display: flex;
    justify-content: center;
  }
  
  body {
    font-family: Verdana;
  }


